// @import '_common';
// $main-bg-color: #fefbfa;
$main-bg-color: #fff;
// $top-main-gap: 64px;
$top-main-gap: 56px;
$dirty-top-main-gap: 56px;
// $dirty-main-gap: $top-main-gap - 8;
$fixed-right-gap: 15px;
$footer-height: 90px;
$default-transition-duration: 0.3s;
$serif: 'Times New Roman', Times, serif;
$default-font: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans',
	'Helvetica Neue', sans-serif;

:root {
	--primary-dark-text: rgba(0, 0, 0, 0.87);
	--secondary-dark-text: rgba(0, 0, 0, 0.54);
	--disabled-dark-text: rgba(0, 0, 0, 0.38);
	--divider-dark-text: rgba(0, 0, 0, 0.12);
	--primary-light-text: #fff;
	--secondary-light-text: rgba(255, 255, 255, 0.7);
	--disabled-light-text: rgba(255, 255, 255, 0.5);
	--divider-light-text: rgba(255, 255, 255, 0.12);
	--cool-dark-color: #495057;
	--main-bg-color: #fff;

	// @each $screen, $size in $grid-breakpoints {
	// 	--screen-width-#{$screen}: #{$size};
	// }
}

%bg-default {
	background-color: $main-bg-color;
}

%secondary-light-text {
	color: var(--secondary-light-text);
	// @include colors($secondary-light-text-colors);
}
.secondary-light-text {
	@extend %secondary-light-text;
}

%cool-dark-color {
	color: var(--cool-dark-color);
}
.cool-dark-color,
.text-cool {
	@extend %cool-dark-color;
}

.text-sm {
	font-size: 0.8em;
}

%default-font {
	font-family: $default-font;
}

%top-sticky {
	position: sticky;
	top: $top-main-gap;
	z-index: 1;
}
.top-sticky {
	@extend %top-sticky;
}

// %top-bottom-sticky {
// 	position: sticky;
// 	top: $top-main-gap;
// 	bottom: 0;
// 	z-index: 1;
// }
// .top-bottom-sticky {
// 	@extend %top-bottom-sticky;
// }

.top-main-gap {
	top: $top-main-gap;
}

// .dirty-main-gap {
// 	top: $dirty-main-gap;
// }

.bottom-0 {
	bottom: 0;
}

body {
	// @extend %bg-default;
	@extend %default-font;
}

#root {
	padding-top: $top-main-gap;
	// padding-top: $dirty-main-gap;
}

.bottom-compensator {
	padding-bottom: $top-main-gap;
}

.min-width-0 {
	min-width: 0;
}

.cursor-pointer {
	cursor: pointer;
}

.bg-default {
	@extend %bg-default;
}

@keyframes keyframe-bounce {
	0% {
		transform: scale(0.8);
	}
	50% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(0.8);
	}
}
.animation-bounce {
	animation: keyframe-bounce 2s infinite linear;
	display: inline-block;
}

// @keyframes keyframes-highlight {
// 	0% {
// 		background-color: var(--bs-warning, yellow);
// 	}
// 	100% {
// 		background-color: #fff;
// 	}
// }
// .animation-highlighted {
// 	animation: 1s linear alternate 0.1s 1 keyframes-highlight;
// }

.header__brand-image_lg {
	width: 1.5em;
	height: 1.5em;
	margin: unset;
	margin-top: -3px;
}

.icon-lg {
	scale: 138%;
}
.icon-xl {
	scale: 162%;
}

.mt-n1 {
	margin-top: -0.25em;
}

.caption__image {
	width: 38vmin;
}

.not-auth {
	#root {
		background-color: #0d0233;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='258' height='258' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23170244' stroke-width='5.5'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23140055'%3E%3Ccircle cx='769' cy='229' r='14'/%3E%3Ccircle cx='539' cy='269' r='14'/%3E%3Ccircle cx='603' cy='493' r='14'/%3E%3Ccircle cx='731' cy='737' r='14'/%3E%3Ccircle cx='520' cy='660' r='14'/%3E%3Ccircle cx='309' cy='538' r='14'/%3E%3Ccircle cx='295' cy='764' r='14'/%3E%3Ccircle cx='40' cy='599' r='14'/%3E%3Ccircle cx='102' cy='382' r='14'/%3E%3Ccircle cx='127' cy='80' r='14'/%3E%3Ccircle cx='370' cy='105' r='14'/%3E%3Ccircle cx='578' cy='42' r='14'/%3E%3Ccircle cx='237' cy='261' r='14'/%3E%3Ccircle cx='390' cy='382' r='14'/%3E%3C/g%3E%3C/svg%3E");
		// background-attachment: fixed;
		// background-size: cover;
	}
}

// .dark-glass-panel {
// 	background-color: rgba(0, 0, 0, 0.1);
// 	padding: 1em;
// 	border-radius: 1em;
// }

// .doc-score__container {
// 	gap: 2px;
// }
// .doc-score__item {
// 	background-color: gray;
// 	background-color: var(--score-bg-color, var(--bs-gray-400));
// 	width: 20px;
// 	height: 5px;
// }
// .doc-score__item_green {
// 	--score-bg-color: var(--bs-green);
// }

// .registry-items-list .table {
// 	// --bs-table-bg: #fefbfa;
// 	--bs-table-bg: rgba(255, 255, 255, 0.95);
// 	// --bs-table-striped-bg: rgba(0, 0, 0, 0.1);
// }

.registry-item {
	&:last-child {
		margin-bottom: 0;
	}
}

li:empty {
	display: none;
}

li:not(:last-child) {
	.registry-item {
		margin-bottom: 0.5em;
	}
}

.matched-word {
	background-color: var(--bs-success, green);
	color: var(--bs-white, white);
	display: inline-block;
	padding: 0 0.25em;
	border-radius: 4px;
	// color: var(--bs-success, green);
}
